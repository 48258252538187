import { Result } from "../BaseNodeService";
import { BaseClientService, ErrorV2 } from "../BaseClientService";
import { ProjectTotalization } from "interfaces/front/business";

export class FrontTopPageService extends BaseClientService {
  url: string;

  constructor() {
    super();
    this.url = this.API_CF_BASE_URL + "/api/front/top_page";
  }

  async getSummaries(input: TopPageSummaryGetInput): Promise<Result<TopPageSummaryGetOutput, ErrorV2>> {
    let query = `representative_id=${input.representative_id ?? ""}`
    query += input.hometown_tax_project_ids.map((id) => `&hometown_tax_project_ids[]=${id}`).join("")
    query += input.corp_hometown_tax_project_ids.map((id) => `&corp_hometown_tax_project_ids[]=${id}`).join("")
    query += input.crowd_funding_project_ids.map((id) => `&crowd_funding_project_ids[]=${id}`).join("")
    query += input.shopping_project_ids.map((id) => `&shopping_project_ids[]=${id}`).join("")
    return super.doSearch<TopPageSummaryGetOutput, ErrorV2>(`${this.url}/summaries?${query}`);
  }
}

export type TopPageSummaryGetInput = {
  representative_id: number | null;
  hometown_tax_project_ids: number[];
  corp_hometown_tax_project_ids: number[];
  crowd_funding_project_ids: number[];
  shopping_project_ids: number[];
};

export type TopPageSummaryGetOutput = {
  representative_summary: Summary | null;
  hometown_tax_summaries: Summary[];
  corp_hometown_tax_summaries: Summary[];
  crowd_funding_summaries: Summary[];
  shopping_summaries: Summary[];
};

export type Summary = {
  project_id: number;
} & ProjectTotalization;
