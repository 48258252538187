import React, { FC, useMemo } from "react";
import Head from "next/head";
import { DESCRIPTION, KEYWORDS, OG_SITENAME, OG_TITLE, TITLE } from "./constants";
import ResetCss from "../layout/ResetCss";

const TopPageHead: FC = () => {
  const robotsValue = useMemo(() => (process.env.APP_ENV === "production" ? "all" : "noindex"), []);
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=no" />
        <title key="title">{TITLE}</title>
        <meta name="description" content={DESCRIPTION} />
        <meta name="keywords" content={KEYWORDS} />
        <link rel="canonical" href={process.env.API_CF_BASE_URL} />
        <meta property="og:title" content={OG_TITLE} />
        <meta property="og:description" content={DESCRIPTION} />
        <meta property="og:image" content={`${process.env.API_CF_BASE_URL}/project-preview/top/animefund_visual_s.jpg`} />
        <meta property="og:url" content={process.env.API_CF_BASE_URL} />
        <meta property="og:site_name" content={OG_SITENAME} />
        <meta property="og:locale" content="ja_JP" />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@animefund" />
        <meta property="fb:app_id" content="2809748165906745" />
        <meta name="robots" content={robotsValue} key="robots" />
      </Head>
      <ResetCss />
    </>
  );
};
export default TopPageHead;
