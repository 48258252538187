// Organism
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ServiceTypeBadge from "components/front/layout/ServiceTypeBadge";
import { Props as StartCountDownAreaProps } from "components/front/project/StartCountDownArea";
import { Project } from "interfaces/front/business";
import dynamic from "next/dynamic";
import Link from "next/link";
import React, { FC, HTMLAttributes, useMemo } from "react";
import styles from "./KeyVisualArea.module.css";
import styled from "styled-components";

const StartCountDownArea = dynamic<StartCountDownAreaProps>(() => import("../project/StartCountDownArea"), { ssr: false });

const KeyVisualArea: FC<HTMLAttributes<HTMLDivElement> & { project: Project; supportStartAtFormat: string | null }> = ({
  project,
  supportStartAtFormat,
  ...props
}) => {
  const pcImageUrl = useMemo(() => project.content.main_content_images[0].url, [project]);
  const spImageUrl = useMemo(() => project.content.key_visual_sp_image?.url || project.content.main_content_images[0].url, [project]);
  const isSupportStarted = useMemo(() => supportStartAtFormat === null, [supportStartAtFormat]);
  const classes = useStyles({ isSupportStarted });
  const isShopping = useMemo(() => project.service_type === "shopping", [project.service_type]);
  return (
    <div {...props} className={`visual_img ${classes.root} ${props.className}`}>
      <Link href={`/project/${project.code}`}>
        <a>
          <div className="visual_img_main pc">
            <img src={pcImageUrl} width="100%" alt="" />
          </div>
          <div className="visual_img_main sp">
            <img src={spImageUrl} width="375" height="402" alt="" />
          </div>
          <div className={styles.shade} />
        </a>
      </Link>
      <Link href={`/project/${project.code}`}>
        <a>
          <div className={styles.title}>
            <StyledServiceTypeBadge serviceType={project.service_type} />
            <p>{project.content.title}</p>
          </div>
        </a>
      </Link>
      {!isSupportStarted && (
        <div className={classes.startCountdownArea}>
          <StartCountDownArea supportStartAt={project.support_start_at} textCentered isShopping={isShopping} />
        </div>
      )}
    </div>
  );
};
export default KeyVisualArea;

const useStyles = makeStyles<Theme, { isSupportStarted: boolean }, string>(() => ({
  root: {},
  startCountdownArea: {
    margin: "20px 0",
  },
}));
const StyledServiceTypeBadge = styled(ServiceTypeBadge)`
  margin-bottom: 8px;
`;
