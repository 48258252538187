import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KeyVisualArea from "components/front/top/KeyVisualArea";
import { Project } from "interfaces/front/business";
import { useSupportStartAtFormat } from "lib/hooks/useSupportStartAtFormat";
import React, { FC, useMemo } from "react";

// Organism
const RepresentativeProjectSection: FC<{ project: Project }> = ({ project }) => {
  const supportStartAtFormat = useSupportStartAtFormat(project.support_start_at);
  const isSupportStarted = useMemo(() => supportStartAtFormat === null, [supportStartAtFormat]);
  const classes = useStyles({ isSupportStarted });
  return (
    <section className={`visual ${classes.root}`}>
      <KeyVisualArea className={classes.keyVisual} project={project} supportStartAtFormat={supportStartAtFormat} />
    </section>
  );
};
export default RepresentativeProjectSection;

const useStyles = makeStyles<Theme, { isSupportStarted: boolean }>((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
      padding: "0",
      marginBottom: "80px",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: "40px",
      marginBottom: "120px",
    },
  },
  keyVisual: {
    [theme.breakpoints.up("md")]: {
      flexBasis: "960px",
    },
    [theme.breakpoints.up("lg")]: {
      flexBasis: "1080px",
    },
    ["@media (min-width:1921px)"]: {
      flexBasis: "1280px",
    },
  },
}));
