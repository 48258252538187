import React from "react";
import { GetServerSideProps, NextPage } from "next";
import dynamic from "next/dynamic";
import * as Sentry from "@sentry/node";
import styled, { css } from "styled-components";
import { useAsyncMemo } from "lib/hooks/useAsyncMemo";
import { LoadingDarkBg } from "components/front/layout/DarkBackground";
import { Header } from "components/front/layout/Header";
import { Project } from "interfaces/front/business";
import CookieHandler from "lib/cookie";
import { FrontAuthProps, withFrontAuth } from "lib/hoc/withFrontAuth";
import { ErrorV2 } from "lib/service/BaseClientService";
import { FrontTopPageService, TopPageGetOutput } from "lib/service/node/FrontTopPageService";
import { Props as FooterProps } from "../components/front/layout/Footer";
import RepresentativeProjectSection from "../components/front/top/RepresentativeProjectSection";
import { FrontTopPageService as ClientTopPageService, TopPageSummaryGetOutput } from "../lib/service/client/FrontTopPageService";
import TopPageHead from "components/front/head/TopPageHead";
import { GeneralFoot } from "components/front/layout/GeneralFoot";
import { Props as CrowdFundingProjectsSectionProps } from "components/front/top/CrowdFundingProjectsSection";
import { Props as HometownTaxProjectSectionProps } from "components/front/top/HometownTaxProjectSection";
import { Props as CorpHometownTaxProjectSectionProps } from "components/front/top/CorpHometownTaxProjectSection";
import { Props as ShoppingProjectSectionProps } from "components/front/top/ShoppingProjectSection";
import { Pickup, Props as PickupSectionProps } from "components/front/top/PickupSection";
import { TopPageProject } from "interfaces/front/page/top";

const CrowdFundingProjectsSection = dynamic<CrowdFundingProjectsSectionProps>(import("components/front/top/CrowdFundingProjectsSection"));
const HometownTaxProjectSection = dynamic<HometownTaxProjectSectionProps>(import("components/front/top/HometownTaxProjectSection"));
const CorpHometownTaxProjectSection = dynamic<CorpHometownTaxProjectSectionProps>(import("components/front/top/CorpHometownTaxProjectSection"));
const ShoppingProjectSection = dynamic<ShoppingProjectSectionProps>(import("components/front/top/ShoppingProjectSection"));
const PickupSection = dynamic<PickupSectionProps>(import("components/front/top/PickupSection"));
const AboutAnimefundSection = dynamic(import("components/front/top/AboutAnimefundSection"));
const DarkBackground = dynamic<Record<string, never>>(() => import("components/front/layout/DarkBackground"), {
  ssr: false,
  loading: LoadingDarkBg,
});
const Footer = dynamic<FooterProps>(() => import("components/front/layout/Footer"));

type Props = {
  representative: TopPageProject | null;
  hometownTaxProjects: TopPageProject[];
  corpHometownTaxProjects: TopPageProject[];
  crowdFundingProjects: TopPageProject[];
  shoppingProjects: TopPageProject[];
  notice: string;
};

const TopPage: NextPage<Props & FrontAuthProps> = ({
  representative,
  hometownTaxProjects,
  corpHometownTaxProjects,
  crowdFundingProjects,
  shoppingProjects,
}) => {
  const summaryOutput = useTopPageSummary(representative, crowdFundingProjects, hometownTaxProjects, corpHometownTaxProjects, shoppingProjects);
  return (
    <>
      <TopPageHead />
      <Header />
      <DarkBackground />
      <Main>
        {representative && summaryOutput?.representative_summary !== null && <RepresentativeProjectSection project={representative} />}
        <StyledCrowdFundingProjectsSection projects={crowdFundingProjects} summaries={summaryOutput?.crowd_funding_summaries} />
        <StyledHometownTaxProjectSection projects={hometownTaxProjects} summaries={summaryOutput?.hometown_tax_summaries} />
        <StyledCorpHometownTaxProjectSection projects={corpHometownTaxProjects} summaries={summaryOutput?.corp_hometown_tax_summaries} />
        <StyledShoppingProjectSection projects={shoppingProjects} summaries={summaryOutput?.shopping_summaries} />
        {PICKUPS.length > 0 && <StyledPickupSection pickups={PICKUPS} />}
        <StyledAboutAnimefundSection />
      </Main>
      <Footer />
      <GeneralFoot />
    </>
  );
};

export const getServerSideProps: GetServerSideProps<Props> = async (context) => {
  const cookieHandler = new CookieHandler(context);
  const notice = cookieHandler.oneTimeNotice();
  const representativeCode = (context.query["representative_code"] as string) ?? "";
  const service = new FrontTopPageService();
  const result = await service.get({ representative_code: representativeCode });
  if (result.isSuccess() && result.value != null) {
    context.res.setHeader("Cache-Control", "s-maxage=3600,max-age=5,must-revalidate");
    const output = result.value as TopPageGetOutput;
    return {
      props: {
        representative: output.representative,
        hometownTaxProjects: output.hometown_tax_projects,
        corpHometownTaxProjects: output.corp_hometown_tax_projects,
        crowdFundingProjects: output.crowd_funding_projects,
        shoppingProjects: output.shopping_projects,
        notice,
      },
    };
  } else {
    return {
      unstable_redirect: {
        permanent: false,
        destination: "/404",
      },
    };
  }
};

export default withFrontAuth(TopPage);

const useTopPageSummary = (
  representative: Project | null,
  crowdFundingProjects: Project[],
  hometownTaxProjects: Project[],
  corpHometownTaxProjects: Project[],
  shoppingProjects: Project[]
): TopPageSummaryGetOutput | undefined => {
  return useAsyncMemo<TopPageSummaryGetOutput>(async () => {
    let result;
    // 取得できなかった場合はサーバ側で取得したプロジェクトを使用する
    const whenError = (): TopPageSummaryGetOutput => ({
      representative_summary: representative ? { ...representative, project_id: Number(representative.id) } : null,
      crowd_funding_summaries: crowdFundingProjects.map((project) => ({ ...project, project_id: Number(project.id) })),
      hometown_tax_summaries: hometownTaxProjects.map((project) => ({ ...project, project_id: Number(project.id) })),
      corp_hometown_tax_summaries: corpHometownTaxProjects.map((project) => ({ ...project, project_id: Number(project.id) })),
      shopping_summaries: shoppingProjects.map((project) => ({ ...project, project_id: Number(project.id) })),
    });
    try {
      const service = new ClientTopPageService();
      result = await service.getSummaries({
        representative_id: representative ? Number(representative.id) : null,
        hometown_tax_project_ids: hometownTaxProjects.map((project) => Number(project.id)),
        corp_hometown_tax_project_ids: corpHometownTaxProjects.map((project) => Number(project.id)),
        crowd_funding_project_ids: crowdFundingProjects.map((project) => Number(project.id)),
        shopping_project_ids: shoppingProjects.map((project) => Number(project.id)),
      });
      if (result.isSuccess()) {
        return result.value as TopPageSummaryGetOutput;
      } else {
        Sentry.captureMessage("プロジェクトサマリ取得に失敗しました。", { extra: { error: result.value as ErrorV2 } });
        return whenError();
      }
    } catch (e) {
      return whenError();
    }
  }, [representative, hometownTaxProjects, corpHometownTaxProjects, crowdFundingProjects, shoppingProjects]);
};

const Main = styled.main`
  & section {
    @media screen and (min-width: 1281px) {
      width: 1080px;
    }
    @media screen and (min-width: 1921px) {
      width: 1280px;
    }
  }
`;
const SectionCss = css`
  @media screen and (max-width: 960px) {
    margin-bottom: 80px;
  }
  @media screen and (min-width: 961px) {
    margin-bottom: 120px;
  }
`;
const StyledCrowdFundingProjectsSection = styled(CrowdFundingProjectsSection)`
  ${SectionCss};
`;

const StyledHometownTaxProjectSection = styled(HometownTaxProjectSection)`
  ${SectionCss};
`;

const StyledCorpHometownTaxProjectSection = styled(CorpHometownTaxProjectSection)`
  ${SectionCss};
`;

const StyledShoppingProjectSection = styled(ShoppingProjectSection)`
  ${SectionCss};
`;

const StyledAboutAnimefundSection = styled(AboutAnimefundSection)`
  ${SectionCss};
`;

const StyledPickupSection = styled(PickupSection)`
  ${SectionCss};
`;

export const PICKUPS: Pickup[] = [
  {
    text: "『カレイドスター20周年アニバーサリー』",
    linkUrl: "/lp/kaleido",
    imageUrl: "https://animefund.com/project-preview/temp/kaleido20th_thumb_1.jpg",
    movieUrl: "https://www.youtube.com/watch?v=0OH-AqTXXK4",
  },
  {
    text: "【応援終了】＜史上初!?＞TVアニメ放送と連動したクラファン開催中！！",
    linkUrl: "/project/seireigensouki",
    imageUrl: "https://animefund.com/project-preview/34/34_main_20210626121637_1.jpg",
    movieUrl: "https://www.youtube.com/watch?v=6OZZSawWlrU",
  },
  {
    text: "【応援終了】目指せ！絶頂1919人！\n劇場版生徒会役員共2 応援クラファン",
    linkUrl: "/project/sydm2",
    imageUrl: "https://animefund.com/project-preview/1/pickup/sydm.jpeg",
    movieUrl: "https://www.youtube.com/watch?v=KHbSmhqHDv8",
  },
  {
    text: "【応援終了】新コース追加！\n桜才学園新入生コースが登場！",
    linkUrl: "/project/sydm2",
    imageUrl: "https://animefund.com/project-preview/1/pickup/sydm2.jpeg",
    movieUrl: "https://www.youtube.com/watch?v=JIx7bxgtnlE",
  },
];
